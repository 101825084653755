<template>
  <div class="main">
    <div class="sub">
      <h3>IAMS Login</h3>
      <b-input @keyup.enter="$refs.password.focus()" v-model="username" placeholder="Benutzername"></b-input>
      <b-input
        ref="password"
        @keyup.enter="login()"
        style="margin-top: 1rem"
        v-model="password"
        type="password"
        placeholder="Passwort"
      ></b-input>
      <b-button variant="primary" @click="login()">Anmelden</b-button>
    </div>
  </div>
</template>

<script>
export default {
  data: function () {
    return {
      user: this.$user,
      username: "",
      password: "",
    };
  },
  methods: {
    login: function () {
      this.$root.apiRequest(
        "/login",
        {
          username: this.username,
          password: this.password,
        },
        (response) => {
          var now = new Date();
          now.setDate(now.getDate() + 7);

          document.cookie =
            "session=" +
            response.data.result.sessionId +
            "; Path=/; Expires=" +
            now.toUTCString() +
            ";";
          this.user.id = response.data.result.id;
          this.user.username = response.data.result.username;
          this.user.admin = response.data.result.admin;
        }
      );
    },
  },
};
</script>

<style scoped>
.main {
  height: 100vh;
  position: relative;
}
.main .sub {
  margin: 0;
  font-size: 4rem;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  text-align: center;
  transform: translate(-50%, -50%);
}
</style>