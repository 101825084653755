import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('../views/HomeView.vue')
  },
  {
    path: '/callcenter',
    name: 'Call Center',
    component: () => import('../views/CallCenterView.vue')
  },
  {
    path: '/workView',
    name: 'Auftagsverwaltung - Arbeitsansicht',
    component: () => import('../views/contractManagement/WorkView.vue')
  },
  {
    path: '/job-money-transfer',
    name: 'ÜEB Aufträge - Arbeitsansicht',
    component: () => import('../views/contractManagement/JobMoneyTransfer.vue')
  },
  {
    path: '/job-money-transfer-cancelled',
    name: 'Ausgebuchte ÜEB Aufträge - Arbeitsansicht',
    component: () => import('../views/contractManagement/CancelledJobMoneyTransfer.vue')
  },
  {
    path: '/all-orders',
    name: 'Auftagsverwaltung - Alle',
    component: () => import('../views/contractManagement/AllView.vue')
  },
  {
    path: '/editContract/:id',
    name: 'Auftagsverwaltung - Bearbeiten',
    component: () => import('../views/contractManagement/EditJob.vue')
  },
  {
    path: '/cancelled-orders',
    name: 'Auftagsverwaltung - Bearbeiten',
    component: () => import('../views/contractManagement/CancelledView.vue')
  },
  {
    path: '/management/customer',
    name: 'Verwaltung - Kunden',
    component: () => import('../views/management/CustomerView.vue')
  },
  {
    path: '/management/employee',
    name: 'Verwaltung - Mitarbeiter',
    component: () => import('../views/management/MonteurView.vue')
  },
  {
    path: '/management/mediators',
    name: 'Verwaltung - Zentralen',
    component: () => import('../views/management/MediatorView.vue')
  },
  {
    path: '/management/contractTypes',
    name: 'Verwaltung - Einsatztypen',
    component: () => import('../views/management/ContractTypeView.vue')
  },
  {
    path: '/management/user',
    name: 'Verwaltung - Benutzer',
    component: () => import('../views/management/UserView.vue')
  },
  {
    path: '/management/templates',
    name: 'Verwaltung - Vorlagen',
    component: () => import('../views/management/TemplateKueView.vue')
  },
  {
    path: '/management/employee/:id',
    name: 'Verwaltung - Mitarbeiter',
    component: () => import('../views/management/edit/MonteurEditView.vue')
  },
  {
    path: '/accounting',
    name: 'Buchhaltung',
    component: () => import('../views/accounting/AccountingView.vue')
  },
  {
    path: '/showDocuments/:fileurl',
    name: 'Dokumente',
    component: () => import('../views/documents/DocumentView.vue')
  },
  {
    path: '/notifications',
    name: 'Benachrichtigungen',
    component: () => import('../views/Notifications.vue')
  },
  {
    path: '/logout',
    name: 'Abmelden',
    component: () => import('../views/user/Logout.vue')
  },
  {
    path: '/prod',
    name: 'Produktionsdatenbank',
    component: () => {
      import('../views/HomeView.vue')
      Vue.prototype.$prod = true;
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
