<template>
  <div id="app">
    <div v-if="this.$user.id > 0">
      <Navigation></Navigation>
      <br />
      <router-view />
    </div>
    <div v-if="this.$user.id == -1">
      <Login></Login>
    </div>
    <div class="startUp" v-if="this.$user.id == -2">
      <div class="subText">
        <img style="height: 15rem" src="@/assets/loading.gif" /><br />
        Warte auf start von<br />IAMS
      </div>
    </div>
    <Search v-if="$root.search == true"></Search>
    <RequestProcessing
      v-if="this.$root.requestProcessing.running"
    ></RequestProcessing>
    <SystemServerInfo
      v-if="!this.$serverUrl().includes('https://richter.myiams.de')"
    ></SystemServerInfo>-
  </div>
</template>

<script>
import Navigation from "@/components/Navigation.vue";
import Login from "@/views/user/Login.vue";
import Search from "@/views/overlayers/Search.vue";
import SystemServerInfo from "@/views/overlayers/SystemServerInfo.vue";
import RequestProcessing from "@/views/overlayers/RequestProcessing.vue";

export default {
  components: {
    Navigation,
    Login,
    Search,
    RequestProcessing,
    SystemServerInfo,
  },
  data: function () {
    return {
      user: this.$user,
    };
  },
  mounted() {
    document.title = 'Richter - MyIAMS';
    this.$root.apiRequest("/session", {}, (response) => {
      this.user.id = response.data.result.id;
      this.user.username = response.data.result.username;
      this.user.admin = response.data.result.admin;
    });
  },
  watch: {
    $route(to, from) {
      
    },
  },
};
</script>


<style>
#app {
  color: #2c3e50;
}
.startUp {
  height: 100vh;
  position: relative;
}
.startUp .subText {
  margin: 0;
  font-size: 4rem;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  text-align: center;
  transform: translate(-50%, -50%);
}
.bg-light {
  background-color: #f8f9fa;
}

b-btn {
  background-color: #007bff;
  color: white;
  border-radius: 0.25rem;
}
</style>
